import './notfound.css';
import {Link} from "react-router-dom";


function NotFound() {
    return (
        <div className="notfound">
            <h1>
                404 page not found<br/>
                <Link to={"/"}>[go to homepage]</Link>
            </h1>
            <img src="confuzled.png" alt=""/>
        </div>
    );
}



export default NotFound;