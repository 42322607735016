import "./article.css"

function SimpleSign() {
	return <div className={"article"}>
		<h1>SimpleSign</h1>
		<img src={"/projects/SimpleSign/img.png"} alt={""}/>
		<p>
			SimpleSign came forth from a request to make a digital notice board. Only a simple solution was needed,
			something that was easy to use and maintain for people with very little technical knowledge.
		</p>
		<p>
			SimpleSign is the result of this, a piece of software that can easily display a variety of files, like powerpoints.
			It utilizes Google drive as a simple cloud system, which makes the contents of the display easily accessible remotely in a familiar environment,
			and means that no additional server setup is required at all.
		</p>
		<p>
			This open-source project can be found <a href={"https://github.com/The1Rogue/SimpleSign"}>here!</a>
		</p>
	</div>
}

export default SimpleSign;