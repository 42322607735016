import './Projects.css';

import projects from "../data/projects.json"
import {Link} from "react-router-dom";


function Projects() {
    let i = true
    return (
        <div className="projects">
            {projects.map(p => (
                <Link to={`/project/${p.name}`} className={"project"}>
                <h1>{p.name}</h1>
                <div className={`entry ${(i = !i) ? "odd" : "even"}`}>
                    <img src={p.img} alt={p.alt}/>
                    <div className="blurt">{p.blurt}<br/><Link to={`/project/${p.name}`}> >> more </Link></div>
                </div>
                </Link>
            ))}
        </div>
    );
}
//todo projects to add:
// digitalSign
// serverSetup
// rainNoise

//todo add alts to images

export default Projects;