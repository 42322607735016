import './Layout.css';
import {Link, Outlet} from "react-router-dom";


function Layout() {
    return (
        <div>
            <div className="header">
                {headerLinks.map(link => (<Link to={link.ref} className="link">{link.name}</Link>))}
            </div>
            <div className="main">
                <Outlet />
            </div>
        </div>
    );
}

let headerLinks = [
    {"name": "home", "ref": "/"},
    {"name": "projects", "ref": "projects"},
    {"name": "about", "ref": "about"},
]


export default Layout;