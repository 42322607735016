import "./article.css"

function FateBound() {
	return <div className={"article"}>
		<h1>LadderBot</h1>
		<img src={"/projects/LadderBot/img.png"} alt={""}/>
		<p>
			Tak is a beautiful abstract game that I love to play. <a href={"https://ustak.org/play-beautiful-game-tak/"}>(Learn more about Tak!)</a>,
			In order to encourage continuous casual play, the Tak community set up a ladder-style format. This was at first managed manually, simply using spreadsheets.
			This cost a lot of effort, and somewhat error-prone. The LadderBot was developed to provide a simple solution for this.
		</p>
		<p>
			LadderBot functions as a simple discord bot, exposing a simple command interface to interact with.
			It is capable of automatically fetch game data from the playtak api in order to calculate game scores, while manual game-results can be entered for over the board games.
			With its easily configurable system you can tweak the ranking and decay systems without trouble.
		</p>
		<p>
			This open-source project can be found <a href={"https://github.com/The1Rogue/LadderBot"}>here!</a>
		</p>

	</div>
}

export default FateBound;