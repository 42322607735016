import './Home.css';

function Home() {
    return (
        <div className="home">
            {left}
            {right}
        </div>
    );
}


let left = <div className="left">
    <div className="gif">
        <img src="cyberwitch.gif" alt=""/>
    </div>
</div>

let right = <div className="right">
    <p className="typing name" style={{"--delay": "100ms", "--steps": 3}}>
        Fay
    </p>
    <p className="typing title" style={{"--delay": "1100ms", "--steps": 10}}>
        CyberWitch
    </p>
</div>

export default Home;