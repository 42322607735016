import "./article.css"
import {Link} from "react-router-dom";

function LyLua() {
	return <div className={"article"}>
		<h1>LyLua</h1>
		<img src={"/projects/LyLua/img.png"} alt={""}/>
		<p>
			In order to maintain a healthy lifestyle, elderly people often require external care and support, especially for elderly dealing with dementia this poses problems.
			LyLua was developed by a group of enthusiastic students at TUE in order to aid these people by monitoring toilet habits, from which it is possible to derive early warning signs for a many common health issues.
		</p>
		<p>
			LyLua is fully contained within a toilet seat, and can be installed with minimal effort. after installation it requires no actions from the elderly user,
			allowing for seamless integration in the daily life. it passively tracks various statistics, which it can then analyze. if any irregularities are detected,
			a notification is sent to a caretaker.
		</p>
		<img src={"/projects/LyLua/toilet.png"}/>
		<p>
			This combined allows for a system which can be easily installed and used by anyone. allowing elderly people to live independently for longer,
			and removes strain from caretakers, of which there is already quite a shortage.
		</p>
		<p>
			This project was made in association with Technical University Eindhoven and Akkedeer.
			As such it has no open source details, if you are still curious however, feel free to <Link to={"/about"}>contact</Link> me!
		</p>
	</div>
}

export default LyLua;