import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Home from './homepage/Home'
import About from './aboutpage/About'
import Layout from './Layout'
import NotFound from "./notfoundpage/notfound";
import Projects from "./projectspage/Projects";
import Project from "./project/Project";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout />}>
                  <Route index element={<Home />}/>
                  <Route path="about" element={<About />}/>
                  <Route path="projects" element={<Projects />}/>
                  <Route path="project/:name" element={<Project />}/>
              </Route>
              <Route path="*" element={<NotFound/>}/>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

