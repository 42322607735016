import "./article.css"
import {Link} from "react-router-dom";

function Languifier() {
	return <div className={"article"}>
		<h1>Languifier</h1>
		<img src={"/projects/Languifier/img.png"} alt={""}/>
		<p>
			While many of us have the privilege to be well-educated, not everyone is that lucky. People may struggle with the more complicated language that is often
			used in things like government letters or job applications, which can make daily life more of a struggle than it needs to be. The ideal solution to this
			problem ofcourse, is to design the world around us with this in mind, but until then Languifier may prove to be a good solution.
		</p>
		<p>
			Languifier aims to aid low-literate people by simplifying the language around them. The device can take a picture of any text around them, it can then recognize the text
			using OCR technology to recognize the text, and provide simplifications and or explanations to details the user may not understand. A physical product was developed
			with this specific target group in mind, although the software could be ported to be used on similar devices like mobile phones.
		</p>
		<img src={"/projects/Languifier/UI.png"} alt={""}/>
		<p>
			This project was made for Technical University Eindhoven.
			As such it has no open source details, if you are still curious however, feel free to <Link to={"/about"}>contact</Link> me!
		</p>
	</div>
}

export default Languifier;