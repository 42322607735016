import "./article.css"

function FateBound() {
	return <div className={"article"}>
		<h1>FateBound</h1>
		<img src={"/projects/FateBound/img.png"} alt={""}/>
		<p>
			FateBound is a mod developed for Minecraft Java edition, which expands upon the game by adding in a complex magic system.
			Players can create their own spells to manipulate their surroundings to their will.
		</p>
		<p>
			Being created in a programmatic way, Fatebounds magic system aims to be easy to learn for anyone, while still offering
			enough complexity to reward players who dive deeper.
		</p>
		<img src={"/projects/FateBound/screenshot.png"} alt={""}/>
		<p>
			Additionally, FateBound features a progression system which differs from what usually features in the game and most mods.
			Players may form a pact with Fae creatures to gain their powers, and do quests as part of this part to progress through the mods content.
		</p>

		<p>
			The mod is currently still in development, so it cannot be downloaded yet. its getting close to its release however.
			then you will be able to find links to it here!
		</p>
	</div>
}

export default FateBound;